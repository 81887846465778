import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import styles from './ueber-uns.module.css';
import Layout from '../components/layout';
import Person from '../components/person';
import BackgroundImage from 'gatsby-background-image';

// add smooth scrolling for page links
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    offset: 0
  });
}

const personDepartments = [
  {
    value: 'Vorstand',
    label: 'Unser Vorstand',
    id: 'vorstand'
  }, {
    value: 'ReferentIn',
    label: 'Unsere ReferentInnen',
    id: 'referentinnen'
  }, {
    value: 'Ortsstelle',
    label: 'Unsere Ortsstellen',
    id: 'ortsstellen'
  }
];

class About extends React.Component {
  render() {
    const persons = get(this, 'props.data.allContentfulPerson.edges');
    const page = get(this, 'props.data.contentfulSeite');
    const defaultPortrait = get(this, 'props.data.contentfulAsset');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <div style={{ background: '#fff' }}>
          <SEO title={page.title} />
          <BackgroundImage 
            backgroundColor="#0e3920"
            className="header"
            fluid={page.heroImage.fluid} 
          >
            <h1 className={styles.heroTitle}>Über uns</h1>
            <nav role="navigation" className={styles.heroNavigation}>
              <ul>
                <li><a href="#vorstand">Vorstand</a></li>
                <li><a href="#referentinnen">ReferentInnen</a></li>
                <li><a href="#ortsstellen">Ortsstellen</a></li>
              </ul>
            </nav>
          </BackgroundImage>
          <div className="container">
            {page.text && 
              <article className="markdown" dangerouslySetInnerHTML={{
                __html: page.text.childMarkdownRemark.html,
              }} />
            }
            {personDepartments.map((department) => (
              <article className={styles.personArticle} key={department.id} id={department.id}>
                  <h2 className={styles.personsTitle}>{department.label}</h2>
                  {persons.filter(({ node }) => node.department === department.value).map(({ node }, index) => (
                    <Person person={node} key={index} placeholderImage={defaultPortrait} />
                  ))}
              </article>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default About;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulSeite(slug: { eq: "ueber-uns" }) {
      title
      heroImage {
        fluid(quality: 100, maxWidth: 1920, cropFocus: TOP) {
          ...GatsbyContentfulFluid
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulPerson(sort: { fields: order }) {
      edges {
        node {
          name
          department
          region
          title
          phone
          email
          portrait {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulAsset(contentful_id: { eq: "361s49BS2XTVD7grkeQBX" }) {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
