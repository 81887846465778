import React from 'react';
import Img from 'gatsby-image';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

import styles from './person.module.css'

export default ({ person, placeholderImage }) => (
  <div className={styles.container}>
    {(person.image || placeholderImage) && 
      <Img className={styles.portrait} fluid={person.portrait ? person.portrait.fluid : placeholderImage.fluid} />
    }
    <div className={styles.infoContainer}>
      <h3 className={styles.name}>{person.name}</h3>
      <h4>{person.title}</h4>
      {person.region && <h4>{person.region}</h4>}
      <div className={styles.social}>
        {person.phone && <a href={`tel:${person.phone}`}><FaPhone /><span>{person.phone}</span></a>}
        {person.email && <a href={`mailto:${person.email}`}><FaEnvelope /><span>{person.email}</span></a>}
      </div>
    </div>
  </div>
);